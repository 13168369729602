import React, { Component } from 'react'
import styled from 'styled-components'
import easing from 'ui/styles/easing'

import IconCoffee from 'ui/components/generic/icons/Coffee'

export default class Notice extends Component {
  render() {
    return (
      <Wrapper>
        <Icon>
          <IconCoffee />
        </Icon>
        <br />
        <a
          href="https://gejststudio.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Made by Gejst/Studio
        </a>
      </Wrapper>
    )
  }
}

// Styles
const Wrapper = styled.div`
  position: absolute;
  bottom: 15px;
  right: 15px;
  font-size: ${props => props.theme.fontSizes.sm};
  text-align: right;

  a {
    color: ${props => props.theme.colors.tones[0]};
    text-decoration: none;
    transition: color 0.3s ${easing.easeOutCubic};
    margin-top: 1em;

    &:hover {
      color: white;
    }
  }
`

const Icon = styled.div`
  width: 25px;
  display: inline-block;

  &:hover {
    cursor: pointer;
    
    svg path {
      fill: white;
    }
  }

  svg {
    width: 100%;
    height: auto;

    path {
      transition: fill 0.3s ${easing.easeOutCubic};
      fill: ${props => props.theme.colors.tones[0]};
    }
  }
`
