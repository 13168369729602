export default {
  font: "'Circular', sans-serif",
  fontSizes: {
    base  : '16px',
    xxxxl : '5.653rem',
    xxxl  : '3.998rem',
    xxl   : '2.827rem',
    xl    : '1.999rem',
    lg    : '1.414rem',
    md    : '1rem',
    sm    : '0.707rem',
    xs    : '0.5rem'
  },
  fontWeights: {
    normal : 'normal',
    medium : 500,
    bold   : 'bold',
    black  : 900
  },
  colors: {
    tones: [
      '#fff',
      '#fff'
    ]
  },
  breakpoints: {
    mobile: 420,
    smallWindow: 570
  }
}
