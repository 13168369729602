export default `
  @font-face {
    font-family: 'Circular';
    src: url('/fonts/circular/CircularStd-Book.eot');
    src: url('/fonts/circular/CircularStd-Book.eot?#iefix') format('embedded-opentype'),
        url('/fonts/circular/CircularStd-Book.woff') format('woff'),
        url('/fonts/circular/CircularStd-Book.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Circular';
    src: url('/fonts/circular/CircularStd-BookItalic.eot');
    src: url('/fonts/circular/CircularStd-BookItalic.eot?#iefix') format('embedded-opentype'),
        url('/fonts/circular/CircularStd-BookItalic.woff') format('woff'),
        url('/fonts/circular/CircularStd-BookItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
  }

  @font-face {
    font-family: 'Circular';
    src: url('/fonts/circular/CircularStd-Bold.eot');
    src: url('/fonts/circular/CircularStd-Bold.eot?#iefix') format('embedded-opentype'),
        url('/fonts/circular/CircularStd-Bold.woff') format('woff'),
        url('/fonts/circular/CircularStd-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: 'Circular';
    src: url('/fonts/circular/CircularStd-BoldItalic.eot');
    src: url('/fonts/circular/CircularStd-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('/fonts/circular/CircularStd-BoldItalic.woff') format('woff'),
        url('/fonts/circular/CircularStd-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
  }

  @font-face {
    font-family: 'Circular';
    src: url('/fonts/circular/CircularStd-Medium.eot');
    src: url('/fonts/circular/CircularStd-Medium.eot?#iefix') format('embedded-opentype'),
        url('/fonts/circular/CircularStd-Medium.woff') format('woff'),
        url('/fonts/circular/CircularStd-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'Circular';
    src: url('/fonts/circular/CircularStd-MediumItalic.eot');
    src: url('/fonts/circular/CircularStd-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('/fonts/circular/CircularStd-MediumItalic.woff') format('woff'),
        url('/fonts/circular/CircularStd-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
  }

  @font-face {
    font-family: 'Circular';
    src: url('/fonts/circular/CircularStd-Black.eot');
    src: url('/fonts/circular/CircularStd-Black.eot?#iefix') format('embedded-opentype'),
        url('/fonts/circular/CircularStd-Black.woff') format('woff'),
        url('/fonts/circular/CircularStd-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
  }

  @font-face {
    font-family: 'Circular';
    src: url('/fonts/circular/CircularStd-BlackItalic.eot');
    src: url('/fonts/circular/CircularStd-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('/fonts/circular/CircularStd-BlackItalic.woff') format('woff'),
        url('/fonts/circular/CircularStd-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
  }
`
