import React from 'react'

const IconReset = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="512"
      height="512"
      viewBox="0 0 512 512"
    >
      <path d="M256 0C114.844 0 0 114.844 0 256s114.844 256 256 256 256-114.844 256-256S397.156 0 256 0zm0 490.667C126.604 490.667 21.333 385.396 21.333 256S126.604 21.333 256 21.333 490.667 126.604 490.667 256 385.396 490.667 256 490.667z" />
      <path d="M419.531 245.938c-4.25-1.469-9.021-.135-11.865 3.396L365 302.667c-3.677 4.604-2.927 11.313 1.667 15 4.615 3.688 11.313 2.917 15-1.667l19.323-24.156c-16.104 65.083-75 113.49-144.99 113.49-55.448 0-106.042-30.521-132.031-79.656-2.771-5.208-9.208-7.198-14.417-4.438-5.208 2.75-7.198 9.208-4.438 14.417 29.698 56.135 87.51 91.01 150.885 91.01 94.104 0 170.667-76.563 170.667-170.667a10.665 10.665 0 00-7.135-10.062zM147 209.333c3.677-4.604 2.927-11.313-1.667-15-4.594-3.667-11.302-2.896-15 1.667l-19.323 24.156c16.104-65.083 75-113.49 144.99-113.49 55.448 0 106.042 30.521 132.031 79.656a10.654 10.654 0 0014.417 4.438c5.208-2.75 7.198-9.208 4.438-14.417-29.698-56.135-87.51-91.01-150.885-91.01-94.104 0-170.667 76.563-170.667 170.667A10.669 10.669 0 0096 266.667c3.177 0 6.26-1.427 8.333-4L147 209.333z" />
    </svg>
  )
}

export default IconReset
