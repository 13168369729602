import React, { Component } from 'react'
import { ThemeProvider } from 'styled-components'
import theme from 'ui/styles/theme'
import GlobalStyles from 'ui/styles/global'

import Timer from 'ui/components/modules/Timer'
import Help from 'ui/components/modules/Help'
import Notice from 'ui/components/modules/Notice'

export default class App extends Component {
  render() {
    return(
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <Timer />
        <Help />
        <Notice />
      </ThemeProvider>
    )
  }
}
