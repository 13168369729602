import React from 'react'

const IconPause = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="512"
      height="512"
      viewBox="0 0 512 512"
    >
      <path d="M256 0C114.833 0 0 114.844 0 256s114.833 256 256 256 256-114.844 256-256S397.167 0 256 0zm0 490.667C126.604 490.667 21.333 385.396 21.333 256S126.604 21.333 256 21.333 490.667 126.604 490.667 256 385.396 490.667 256 490.667z" />
      <path d="M224 149.333h-64A10.66 10.66 0 00149.333 160v192A10.66 10.66 0 00160 362.667h64A10.66 10.66 0 00234.667 352V160A10.66 10.66 0 00224 149.333zm-10.667 192h-42.667V170.667h42.667v170.666zm138.667-192h-64A10.66 10.66 0 00277.333 160v192A10.66 10.66 0 00288 362.667h64A10.66 10.66 0 00362.667 352V160A10.66 10.66 0 00352 149.333zm-10.667 192h-42.667V170.667h42.667v170.666z" />
    </svg>
  )
}

export default IconPause
