import { css } from 'styled-components'
import theme from './theme'

const sizes = theme.breakpoints

export const media = {
  mobile: (...args) => css`
    @media (max-width: ${sizes.mobile}px) {
      ${css(...args)}
    }
  `,

  smallWindow: (...args) => css`
    @media (min-width: ${sizes.mobile + 1}px) and (max-width: ${sizes.smallWindow}px) {
      ${css(...args)}
    }
  `
}
