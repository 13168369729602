import React, { Component } from 'react'
import styled from 'styled-components'
import easing from 'ui/styles/easing'

export default class Help extends Component {
  render() {
    return (
      <Wrapper>
        <p>Enter / Space: Pause or resume timer</p>
        <p>Backspace: Reset timer</p>
      </Wrapper>
    )
  }
}

// Styles
const Wrapper = styled.div`
  position: absolute;
  bottom: 15px;
  left: 15px;
  font-size: ${props => props.theme.fontSizes.sm};

  p {
    color: ${props => props.theme.colors.tones[0]};
    text-decoration: none;
    transition: color 0.3s ${easing.easeOutCubic};
    margin-bottom: 0;

    &:hover {
      color: white;
    }
  }
`
