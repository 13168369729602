import { createGlobalStyle } from 'styled-components'
import styledNormalize from 'styled-normalize'
import theme from 'ui/styles/theme'
import { media } from 'ui/styles/media'

import fonts from './fonts'

export default createGlobalStyle`
  ${styledNormalize}

  ${fonts}

  body, html {
    font-family: 'Circular', sans-serif;
    background: #840f00;
    color: white;
    font-size: ${theme.fontSizes.base};

    ${media.mobile`
      font-size: 11px;
    `}

    ${media.smallWindow`
      font-size: 12px;
    `}
  }

  h1 {
    font-size: ${theme.fontSizes.xxxl};
  }

  h2 {
    font-size: ${theme.fontSizes.xxl};
  }

  h3 {
    font-size: ${theme.fontSizes.xl};
  }

  h4 {
    font-size: ${theme.fontSizes.lg};
  }
`
